import { SnackbarArticle } from "../models/types";

export const getArticleCategoryDefaultImage = (
  category: "lunch" | "snack" | "drink" | undefined,
): string => {
  const baseUrl = import.meta.env.VITE_APP_API_URI;
  switch (category) {
    case "lunch":
      return `${baseUrl}/images/public/lunch.jpg`;
    case "snack":
      return `${baseUrl}/images/public/drinks.jpg`;
    case "drink":
      return `${baseUrl}/images/public/drinks.jpg`;
    default:
      return `${baseUrl}/images/public/drinks.jpg`;
  }
};

export const getProxiedArticleImage = (article: SnackbarArticle): string => {
  if (article.imageUrl == undefined || article.imageUrl == "") {
    return getArticleCategoryDefaultImage(article.category);
  }

  return `${import.meta.env.VITE_APP_API_URI}/images/article/${
    article.articleId
  }`;
};
