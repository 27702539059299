import React, { FC } from "react";
import {
  SnackbarArticle,
  SnackbarBookingWithDescription,
} from "../../../models/types";
import { Box } from "@mittwald/flow-components/dist/components/Box/Box";
import { Button } from "@mittwald/flow-react-components/Button";
import { LunchOrderConfirmationModal } from "../modals/LunchOrderConfirmationModal";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { OrderLimitReachedBanner } from "../../../components/OrderLimitReachedBanner";
import {
  OrderLimitStateResponse,
  useOrderLimit,
} from "../../../hooks/useOrderLimit";
import { DateTime } from "luxon";
import Heading from "@mittwald/flow-react-components/Heading";
import { IconToolsKitchen2 } from "@tabler/icons-react";
import Icon from "@mittwald/flow-react-components/Icon";
import { Action } from "@mittwald/flow-react-components/Action";
import InlineAlert from "@mittwald/flow-react-components/InlineAlert";

interface BookNowBannerProps {
  articles: SnackbarArticle[];
  bookings: SnackbarBookingWithDescription[];
}

export const BookNowBanner: FC<BookNowBannerProps> = (props) => {
  const { articles, bookings } = props;

  const orderLimit = useOrderLimit();

  const articleBookingModalOpener = (): void => {
    if (!articles[0]) {
      return;
    }
    showModal(
      "OrderConfirmation",
      <LunchOrderConfirmationModal article={articles[0]} />,
    );
  };

  if (articles.length === 0 || !articles[0]) return <></>;

  const todaysBookingEntry = bookings.find((booking) =>
    DateTime.fromISO(booking.bookedDate).hasSame(DateTime.now(), "day"),
  );

  return (
    <Box _flexDirection="column" _rowGap="l">
      <Box
        _flexDirection="row"
        _flexWrap
        _justifyContent="space-between"
        _noShrink
        _rowGap="l"
      >
        <Box _my="auto">
          <Heading>
            <Icon>
              <IconToolsKitchen2 />
            </Icon>
            Du hast Mittagessen gegessen? -&gt; Mittagessen buchen!
          </Heading>
        </Box>
        <Box _my="auto">
          <Action action={articleBookingModalOpener}>
            {todaysBookingEntry && todaysBookingEntry.orderAlreadyBooked ? (
              <Button
                color={
                  orderLimit.state === OrderLimitStateResponse.REACHED
                    ? "danger"
                    : "primary"
                }
                variant="soft"
              >
                Nachbuchen
              </Button>
            ) : (
              <Button
                color={
                  orderLimit.state === OrderLimitStateResponse.REACHED
                    ? "danger"
                    : "primary"
                }
                variant="solid"
              >
                Jetzt buchen
              </Button>
            )}
          </Action>
        </Box>
      </Box>
      {todaysBookingEntry && todaysBookingEntry.orderAlreadyBooked && (
        <InlineAlert status={"success"}>
          <Heading>
            Das heutige Essen wurde automatisch für Dich gebucht.
          </Heading>
        </InlineAlert>
      )}
      <OrderLimitReachedBanner orderLimit={orderLimit} />
    </Box>
  );
};
