import React, { FC, useState } from "react";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import TextField from "@mittwald/flow-components/dist/components/TextField/TextField";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import Page from "@mittwald/flow-renderer/dist/components/Page/Page";
import { H1 } from "@mittwald/flow-components/dist/components/H1/H1";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { RegisterInputs } from "./LoginInputs";
import { ActionBar } from "@mittwald/flow-components/dist/components/ActionBar/ActionBar";
import sessionStore from "../../login/session";
import RegisterConfirmationModal from "./RegisterConfirmationModal";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import Box from "@mittwald/flow-components/dist/components/Box";
import Text from "@mittwald/flow-components/dist/components/Text/Text";
import TextLink from "@mittwald/flow-components/dist/components/TextLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import Icon from "@mittwald/flow-components/dist/components/Icon";

export const Register: FC = () => {
  const linkTo = useLinkBuilder();
  const confirmationModalVisibility = useVisibilityController(false);
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const form = useForm<RegisterInputs>({
    onSubmit: async (values) => {
      const response = await snackbarApi.registerUser.request({
        requestBody: {
          employeeId: values.employeeId,
          email: values.email,
          password: values.password,
        },
      });

      assertStatus(response, 201);

      const employeeId: string = response.content.employeeId;
      const email: string = response.content.email;
      sessionStore.register(email, employeeId);
      confirmationModalVisibility.hide();
      setShowSuccessScreen(true);
    },
  });
  const registrationFormWatcher = form.watch();

  if (
    showSuccessScreen &&
    confirmationModalVisibility.exitAnimationState === "done"
  ) {
    return (
      <TranslationProvider name="successRegister" type="page">
        <ColumnLayout>
          <Icon _color="brand-primary" _size="xl" icon={faEnvelope} />
          <H1 i18n="title"></H1>
          <Box>
            <Text i18n="content"></Text>
          </Box>
          <TextLink action={linkTo("login")} i18n="loginLink" />
        </ColumnLayout>
      </TranslationProvider>
    );
  }

  return (
    <Page>
      <Form controller={form}>
        <RegisterConfirmationModal
          employeeId={registrationFormWatcher.employeeId}
          visibilityController={confirmationModalVisibility}
        />
        <SectionItem>
          <H1>Registrierung</H1>
          <TextField
            label="registerEmployeeId"
            name="employeeId"
            rules={{
              required: true,
              minLength: 4,
              maxLength: 4,
              validate: {
                employeeId: (v: string) => {
                  const regex = /[0-9]{4}/;
                  return regex.test(v);
                },
              },
            }}
          />
          <TextField
            autoComplete="email"
            data-1p-ignore
            label="registerEmail"
            name="email"
            rules={{
              required: true,
              validate: {
                mittwaldEmail: (v: string) => {
                  const regex = /^[^+]+@mittwald\.de$/;
                  return regex.test(v);
                },
              },
            }}
            type="email"
          />
          <TextField
            autoComplete="password"
            label="password"
            name="password"
            rules={{ required: true, minLength: 12 }}
            type="passwordWithGenerator"
          />
          <ActionBar
            primary={{
              text: "register",
              action: form.handleSubmit(() => {
                confirmationModalVisibility.show();
              }),
            }}
          />
        </SectionItem>
      </Form>
    </Page>
  );
};

export default Register;
