import React, { FC, useState } from "react";
import { Page } from "@mittwald/flow-renderer/dist/components/Page";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink/TextLink";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { TextField } from "@mittwald/flow-components/dist/components/TextField/TextField";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { H1 } from "@mittwald/flow-components/dist/components/H1/H1";
import { LoginInputs } from "./LoginInputs";
import { ActionBar } from "@mittwald/flow-components/dist/components/ActionBar/ActionBar";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { sessionStore } from "../../login/session";
import { setDefaultAccessToken } from "../../api/clients/setDefaultAccessToken";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import { useCheckAuthenticated } from "../../hooks/useCheckAuthenticated";
import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import { Banner } from "@mittwald/flow-components/dist/components/Banner/Banner";

export const Login: FC = () => {
  const linkTo = useLinkBuilder();
  const goto = useGotoLink();
  const authenticated = useCheckAuthenticated(false);

  const [unverified, setUnverified] = useState(false);
  const form = useForm<LoginInputs>({
    onSubmit: async (values) => {
      setUnverified(false);
      const response = await snackbarApi.loginUser.request({
        requestBody: {
          employeeId: values.employeeId,
          password: values.password,
        },
      });
      if (response.status == 412) {
        setUnverified(true);
        return false;
      }
      if (response.status == 200) {
        const token: string = response.content.jwt;
        const employeeId: string = response.content.employeeId;
        sessionStore.login(token, employeeId);
        setDefaultAccessToken(token);
        if (response.content.role === "accounting") {
          goto("accounting");
        } else {
          goto("app");
        }
      } else {
        return false;
      }
    },
  });

  return authenticated ? (
    <RedirectToLink linkId={"app"} />
  ) : (
    <Page>
      {unverified && (
        <Banner
          description={{
            id: "emailNotVerifiedBanner.description",
            values: {
              registerLink: (content: any) => (
                <TextLink action={linkTo("register")}>{content}</TextLink>
              ),
            },
          }}
          headline="emailNotVerifiedBanner.headline"
          warning
        />
      )}
      <Form controller={form}>
        <SectionItem>
          <H1>Login</H1>
          <TextField
            autoComplete="username"
            label="employeeId"
            name="employeeId"
            rules={{
              required: true,
              minLength: 4,
              maxLength: 4,
              validate: {
                employeeId: (v: string) => {
                  const regex = /[0-9]{4}/;
                  return regex.test(v);
                },
              },
            }}
          />
          <TextField
            autoComplete="password"
            label="password"
            name="password"
            rules={{ required: true }}
            type="password"
          />
          <TextLink
            action={linkTo("passwordForgot")}
            text="Passwort vergessen?"
          />
          <ActionBar
            primary={{
              text: "login",
              action: { form },
            }}
          />
        </SectionItem>
      </Form>
    </Page>
  );
};
export default Login;
