import { FC } from "react";
import React from "react";
import Box from "@mittwald/flow-components/dist/components/Box/Box";
import { MainNavigation } from "@mittwald/flow-renderer/dist/components/MainNavigation";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import {
  useCheckAdminPermission,
  useCheckAzubiPermission,
} from "../hooks/useCheckAdminPermission";
import { faBox } from "@fortawesome/pro-regular-svg-icons/faBox";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { faChartSimple } from "@fortawesome/pro-regular-svg-icons/faChartSimple";

export const MainBottomNavigation: FC = () => {
  const buildLink = useLinkBuilder();
  const isUserAdmin = useCheckAdminPermission();
  const isUserAzubi = useCheckAzubiPermission();

  return (
    <Box _flexDirection="column" _grow>
      <MainNavigation.Section _grow>
        {isUserAdmin && (
          <>
            <MainNavigation.Item
              action={buildLink("statistics")}
              i18n="statistics"
              icon={faChartSimple}
            />
            <MainNavigation.Item
              action={buildLink("admin")}
              i18n="admin"
              icon={faGear}
            />
          </>
        )}
        {isUserAzubi && (
          <>
            <MainNavigation.Item
              action={buildLink("editArticles")}
              i18n="editArticles"
              icon={faBox}
            />
          </>
        )}
      </MainNavigation.Section>
      <MainNavigation.Section>
        <MainNavigation.Item
          action={buildLink("history")}
          i18n="history"
          icon={faHistory}
        />
      </MainNavigation.Section>
    </Box>
  );
};
