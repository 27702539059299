import Page from "@mittwald/flow-renderer/dist/components/Page/Page";
import React, { FC, useEffect, useMemo, useState } from "react";
import { snackbarApi } from "../../api/SnackbarApiClient";
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";
import { ContextMenuTrigger } from "@mittwald/flow-react-components/ContextMenu";
import Button from "@mittwald/flow-react-components/Button";
import ContextMenu, {
  MenuItem,
} from "@mittwald/flow-react-components/ContextMenu";
import { Section } from "@mittwald/flow-react-components/Section";
import { useCheckAdminPermission } from "../../hooks/useCheckAdminPermission";
import DateRangePicker from "@mittwald/flow-react-components/DateRangePicker";
import {
  CalendarDate,
  DateValue,
  getLocalTimeZone,
  today,
} from "@internationalized/date";
import { RangeValue } from "@react-types/shared";
import { debounce } from "es-toolkit";
import IllustratedMessage from "@mittwald/flow-react-components/IllustratedMessage";
import { IconApp } from "@mittwald/flow-react-components/Icons";
import { Heading } from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";

export const StatisticsOverview: FC = () => {
  const articleResource = snackbarApi.getAllArticles.getResource({});
  const articleResponse = articleResource.useWatchData();

  const isAdmin = useCheckAdminPermission();

  const [selectedArticles, setSelectedArticles] = useState(
    new Set(
      articleResponse.articles.slice(0, 3).map((article) => article.articleId),
    ),
  );

  const [dateRange, setDateRange] = useState<RangeValue<DateValue>>({
    start: today(getLocalTimeZone()).subtract({ days: 6 }),
    end: today(getLocalTimeZone()),
  });

  const orderResource = snackbarApi.getOrderStatistics.getResource({
    query: {
      startDate: dateRange.start.toDate(getLocalTimeZone()).toISOString(),
      endDate: dateRange.end.toDate(getLocalTimeZone()).toISOString(),
      articles: Array.from(selectedArticles),
    },
  });

  const orderResponse = orderResource.useWatchData();

  useEffect(() => {
    orderResource.expire();
    articleResource.expire();
  }, []);

  function selectAll(): void {
    setSelectedArticles(
      new Set(articleResponse.articles.map((article) => article.articleId)),
    );
  }

  function unselectAll(): void {
    setSelectedArticles(new Set());
  }

  function randomHexColor(): string {
    return (
      "#" +
      Math.floor(Math.random() * 16777215)
        .toString(16)
        .padStart(6, "0")
    );
  }

  const articles = useMemo(
    () =>
      articleResponse.articles
        .filter((article) => {
          if (!isAdmin) {
            return article.articleId != "mittag";
          }
          return true;
        })
        .map((article) => ({
          ...article,
          color: randomHexColor(),
        })),
    [isAdmin, articleResponse.articles],
  );

  const updateArticles = debounce(
    (ids: Set<string>) => setSelectedArticles(ids as unknown as Set<string>),
    500,
  );
  const updateDateRange = debounce(
    (range: RangeValue<DateValue>) => setDateRange(range),
    500,
  );

  return (
    <Page>
      <Section>
        <div
          style={{
            display: "flex",
            justifyItems: "start",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "end",
          }}
        >
          <ContextMenuTrigger>
            <Button>Artikel auswählen</Button>
            <ContextMenu
              defaultSelectedKeys={selectedArticles}
              selectionMode="multiple"
              onSelectionChange={(ids) => {
                updateArticles(ids as unknown as Set<string>);
              }}
            >
              {articles.map((article) => (
                <MenuItem id={article.articleId} key={article.articleId}>
                  {article.name}
                </MenuItem>
              ))}
            </ContextMenu>
          </ContextMenuTrigger>
          <Button color="accent" onPress={selectAll}>
            Alle Artikel auswählen
          </Button>
          <Button color="danger" onPress={unselectAll}>
            Keine Artikel auswählen
          </Button>
          <DateRangePicker
            defaultValue={{
              start: today(getLocalTimeZone()).subtract({ days: 6 }),
              end: today(getLocalTimeZone()),
            }}
            maxValue={today(getLocalTimeZone())}
            minValue={new CalendarDate(2023, 8, 1)}
            onChange={updateDateRange}
          />
        </div>
        {selectedArticles.size > 0 ? (
          <div style={{ height: "clamp(50px, 70vh, 600px)", width: "100%" }}>
            <ResponsiveContainer>
              <AreaChart
                data={orderResponse.results}
                margin={{
                  top: 5,
                  right: 5,
                  left: 5,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <defs>
                  {articles
                    .filter((it) => selectedArticles.has(it.articleId))
                    .map((article) => (
                      <linearGradient
                        id={article.articleId}
                        key={article.articleId}
                        x1="0"
                        x2="0"
                        y1="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor={article.color}
                          stopOpacity={0.8}
                        />
                        <stop
                          offset="95%"
                          stopColor={article.color}
                          stopOpacity={0.1}
                        />
                      </linearGradient>
                    ))}
                </defs>
                {articles
                  .filter((it) => selectedArticles.has(it.articleId))
                  .map((article) => (
                    <>
                      <Area
                        color={article.color}
                        dataKey={article.articleId}
                        fill={`url(#${article.articleId})`}
                        fillOpacity={1}
                        name={article.name}
                        stroke={article.color}
                        type="monotone"
                      />
                    </>
                  ))}
              </AreaChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <IllustratedMessage>
            <IconApp />
            <Heading>Keine Artikel ausgewählt</Heading>
            <Text>
              Wähle Artikel aus, die du in der Grafik anzeigen möchtest um die
              Anzahl der Buchungen dieser zu vergleichen.
            </Text>
          </IllustratedMessage>
        )}
      </Section>
    </Page>
  );
};

export default StatisticsOverview;
