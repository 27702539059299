import { FC } from "react";
import React from "react";
import { SnackbarArticle } from "../../../models/types";
import { useResourceList } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/useResourceList";
import { priceColumnRenderer } from "../../../components/Article/priceColumnRenderer";
import { articleFilter } from "../../../components/Article/articleFilter";
import { MainActionMapper } from "@mittwald/flow-components/dist/components/ResourceList";
import { ResourceList } from "@mittwald/flow-components/dist/components/ResourceList/components/ResourceList/ResourceList";
import { nameColumnRenderer } from "../../../components/Article/nameColumnRenderer";
import { getProxiedArticleImage } from "../../../lib/image";

interface ArticleResourceListProps {
  articles: SnackbarArticle[];
  mainAction: MainActionMapper<SnackbarArticle>;
  defaultImage: string;
}
export const ArticleResourceList: FC<ArticleResourceListProps> = (props) => {
  const { articles, mainAction } = props;
  const resourceList = useResourceList<SnackbarArticle>({
    resources: articles,
    nameColumn: nameColumnRenderer,
    mainActionMapper: mainAction,
    bodyColumns: [
      {
        id: "price",
        renderer: priceColumnRenderer,
      },
    ],
    filter: articleFilter,
  });

  return (
    <ResourceList
      {...resourceList}
      columnLayout={{ body: [{ key: "name" }] }}
      defaultView="tiles"
      hideListView
      hideSorting
      imageUrl={(article) => {
        return {
          url: getProxiedArticleImage(article),
        };
      }}
    />
  );
};
